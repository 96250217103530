import React from 'react'

export default function Aboutme() {
  return (
    <>
    <div className="flex w-full justify-center items-center">
    <div className="flex flex-col w-full justify-center items-center ">
      <br />
     
      <h1 className=" text-2xl">About Me</h1>
      <br />
     
      <p>I am the on hand, Go-to resource for all of your Notary and Signing-Agent needs. <br />
      I am certified by Washington State Notary Standards. <br />
      Please feel free to request my Credentials.
      </p>
      </div>
      
    </div>
    </>
  )
}
